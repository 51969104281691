import React from 'react'
import {StyledTeams} from './Team.styles'
import {MedicalTeam} from '../../CategoryPage/MedicalTeam'
import isEmpty from 'lodash/isEmpty'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'

function Team({state = {}, actions = {}}) {
  const {sectionName} = state || {}

  if (isEmpty(state)) {
    return null
  }
  return (
    <StyledTeams data-scrollsection={sectionName}>
      <MedicalTeam state={state} actions={actions} />
    </StyledTeams>
  )
}

export default withCustomErrorBoundary(Team, 'Team')
